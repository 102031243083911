import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Team from '@/views/Team.vue'
import MyAccount from '@/views/MyAccount.vue'
import PasswordForgotten from '@/views/PasswordForgotten.vue'
import ResetPassword from '@/views/ResetPassword'
import PaymentJourney from '@/views/PaymentJourney.vue'
import ActivateGift from '@/views/ActivateGift.vue'
import Shop from '@/views/Shop.vue'
import CartJourney from '@/views/CartJourney.vue'
import Login from '@/views/Login.vue'
import Signup from '@/views/Signup.vue'
import WineDetail from '@/views/WineDetail.vue'
import { useAppStateStore } from '@/stores/appState'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/team',
    name: 'Les Compères',
    component: Team
  },
  {
    path: '/my-account',
    name: 'Mon Compte',
    component: MyAccount
  },
  {
    path: '/password-forgotten',
    name: 'Mot de passe oublié',
    component: PasswordForgotten
  },
  {
    path: '/reset-password',
    name: 'Réinitialisation du mot de passe',
    component: ResetPassword
  },
  {
    path: '/activate-gift',
    name: 'Activez votre cadeau',
    component: ActivateGift
  },
  {
    path: '/payment-journey',
    name: 'Commande',
    component: PaymentJourney
  },
  {
    path: '/shop',
    name: 'La Boutique',
    component: Shop
  },
  {
    path: '/cart',
    name: 'Mon Panier',
    component: CartJourney
  },
  {
    path: '/login',
    name: 'Connexion',
    component: Login
  },
  {
    path: '/signup',
    name: 'Inscription',
    component: Signup
  },
  {
    path: '/wines/:id',
    name: "Détail d'un vin",
    component: WineDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/cart',
    name: "Parcours d'achat de la boutique",
    component: CartJourney,
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes
})

const paramsToPreserve = ['page', 'priceRangesSelected', 'categoriesSelected', 'search', 'countriesSelected', 'selectionsSelected']

const getRouteWithParamsPreserved = (to, from) => {
  let atLeastOneParamToPreserve = false

  for (const paramToPreserve of paramsToPreserve) {
    if (from?.query?.[paramToPreserve] && !to?.query?.[paramToPreserve]) {
      to.query[paramToPreserve] = from.query[paramToPreserve]
      atLeastOneParamToPreserve = true
    }
  }
  return atLeastOneParamToPreserve ? to : null
}

router.beforeEach((to, from) => {
  const appStateStore = useAppStateStore()
  if (to.meta.requiresAuth && !appStateStore.user) {
    return {
      path: '/login',
      query: { redirect: to.fullPath }
    }
  }

  if (to.path !== '/shop' || from.path !== '/shop') {
    return getRouteWithParamsPreserved(to, from)
  }
})

export default router
