<template>
  <div class="mt-8 mx-auto w-full max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <div class="mb-5" v-if="title">
        <p class="text-xl font-semibold text-center">
          {{ title }}
        </p>
      </div>
      <form class="space-y-6">
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700"> Adresse e-mail </label>
          <div class="mt-1">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              autocomplete="email"
              required=""
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700"> Mot de passe </label>
          <div class="mt-1">
            <input
              id="loginPassword"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required=""
              class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center"></div>

          <div class="text-sm">
            <router-link to="/password-forgotten" class="font-medium text-primary hover:text-primary">
              Mot de passe oublié ?
            </router-link>
          </div>
        </div>

        <div>
          <Button @clicked="login" :loading="loading"> Se connecter </Button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useAppStateStore } from '@/stores/appState'
import { useToastStore } from '@/stores/toast'
import Button from '@/components/Button'
import authenticationApi from '@/api/authentication'

export default {
  name: 'LoginForm',
  components: {
    Button
  },
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      email: '',
      password: ''
    }
  },
  methods: {
    ...mapActions(useToastStore, ['showToast']),
    ...mapActions(useAppStateStore, ['updateUser']),
    async login() {
      this.loading = true
      try {
        const response = await authenticationApi.login(this.email, this.password)
        localStorage.setItem('Authorization', response.data.token)
        delete response.data.token
        this.updateUser(response.data)
        this.$emit('success')
      } catch (err) {
        this.showToast({
          error: true,
          title: 'Erreur',
          message: err.response.data.message
        })
      }
      this.loading = false
    }
  }
}
</script>
