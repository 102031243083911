<template>
  <Disclosure as="nav" id="nav" class="bg-primary shadow-sm" v-slot="{ open }">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Horizontal menu only on large screens -->
      <div class="hidden md:flex justify-between h-28">
        <div class="flex">
          <div class="block h-8 w-8"></div>
          <div class="block h-8 w-8"></div>
        </div>
        <div class="flex text-center">
          <div class="hidden md:-my-px md:flex md:space-x-8">
            <router-link
              :active-class="isSubscriptionActive ? 'router-link-active' : ''"
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/payment-journey?paymentJourneyType=subscription"
              >Je m'abonne</router-link
            >
            <router-link
              :active-class="isGiftActive ? 'router-link-active' : ''"
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/payment-journey?paymentJourneyType=gift"
              >J'offre un abonnement</router-link
            >
            <div class="flex-shrink-0 flex items-center z-10">
              <!-- Here exact property allows to only consider the page '/' active for this exact url, not any url starting by '/' -->
              <router-link class="bg-primary rounded-full" to="/" exact>
                <img class="hidden md:block h-36 w-auto m-2 pt-6" src="@/assets/logo.png" alt="La Chaussette" />
              </router-link>
            </div>
            <router-link
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/team"
              >Les compères</router-link
            >
            <router-link
              class="text-white text-sm bg-tertiary bg-opacity-0 hover:bg-opacity-20 uppercase my-auto"
              to="/shop"
              >La boutique</router-link
            >
          </div>
        </div>
        <div class="flex my-auto">
          <router-link class="text-white mx-4 hover:opacity-20" to="/cart">
            <ShoppingCart class="block h-10 w-10" />
          </router-link>
          <Menu as="div" class="relative ml-3">
            <div>
              <MenuButton class="flex rounded-full text-sm focus:outline-none text-white">
                <span class="sr-only">Open user menu</span>
                <font-awesome-icon icon="fa-solid fa-circle-user" class="text-white h-10 w-10 hover:opacity-20" />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <router-link
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    to="/my-account"
                    >Mon Compte</router-link
                  >
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }">
                  <a
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    :href="
                      'https://checkout.lachaussette.eu/p/login/test_00g01a8gf2Ts91K000?prefilled_email=' + user.c_email
                      // TODO: Won't work anymore cause we deactivated custom domains, now use checkout.stripe.com
                    "
                    target="_blank"
                    >Paiement et Factures</a
                  >
                </MenuItem> -->
                <MenuItem v-slot="{ active }">
                  <button
                    :class="[active ? 'bg-gray-100' : '', 'flex px-4 py-2 text-sm text-error w-full justify-start']"
                    @click="disconnect"
                  >
                    Se déconnecter
                  </button>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <!-- Home button still visible on mobile -->
    <div class="flex justify-between h-16 md:hidden px-3">
      <div class="">
        <router-link to="/" exact class="">
          <img class="h-16 w-auto z-50" src="@/assets/logo.png" alt="La Chaussette" />
        </router-link>
      </div>
      <div class="-mr-2 flex items-center">
        <!-- Mobile menu button -->
        <DisclosureButton
          class="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-gray-300 focus:outline-none"
        >
          <span class="sr-only">Open main menu</span>
          <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
          <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
        </DisclosureButton>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="pt-2 pb-3 space-y-1">
        <router-link
          :active-class="isSubscriptionActive ? 'router-link-active' : ''"
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/payment-journey?paymentJourneyType=subscription"
          >JE M'ABONNE</router-link
        >
        <router-link
          :active-class="isGiftActive ? 'router-link-active' : ''"
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/payment-journey?paymentJourneyType=gift"
          >J'OFFRE UN ABONNEMENT</router-link
        >
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/team"
          >LES COMPÈRES</router-link
        >
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/shop"
          >LA BOUTIQUE</router-link
        >
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 flex pl-3 pr-4 py-2 border-l-4 text-base font-medium items-center"
          to="/cart"
          ><ShoppingCart iconClasses="w-6 h-6"
        /></router-link>
        <router-link
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          to="/my-account"
          >MON COMPTE</router-link
        >
        <!-- <a
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          :href="'https://checkout.lachaussette.eu/p/login/test_00g01a8gf2Ts91K000?prefilled_email=' + user.c_email"
          // TODO: Won't work anymore cause we deactivated custom domains, now use checkout.stripe.com
          target="_blank"
          >PAIEMENT ET FACTURES</a
        > -->
        <button
          class="border-transparent text-white bg-tertiary bg-opacity-0 hover:bg-opacity-20 hover:border-gray-300 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          @click="disconnect"
        >
          SE DÉCONNECTER
        </button>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { MenuIcon, XIcon } from '@heroicons/vue/outline'
import { mapState, mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'
import { useAppStateStore } from '@/stores/appState'

import ShoppingCart from '@/components/header/ShoppingCart'

export default {
  name: 'Header',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    MenuIcon,
    XIcon,
    ShoppingCart
  },
  setup() {
    return {}
  },
  props: {
    msg: String
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    ...mapState(useAppStateStore, {
      user: state => state && state.user
    }),
    isGiftActive() {
      return this.order.paymentJourneyType === 'gift'
    },
    isSubscriptionActive() {
      return this.order.paymentJourneyType === 'subscription'
    }
  },
  methods: {
    ...mapActions(useAppStateStore, ['resetState']),
    async disconnect() {
      await this.$router.push('/', { query: undefined })
      this.resetState()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
