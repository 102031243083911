import { http } from '@/api/index'

export default {
  getAll() {
    return http.get('/clients')
  },
  getUser(clientId) {
    return http.get(`/client?clientId=${clientId}`)
  },
  getAuthenticatedUser() {
    return http.get('/authenticated-client')
  }
}
