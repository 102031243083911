<template>
  <div class="flex flex-col items-center mt-4">
    <div class="w-full lg:w-2/5">
      <LoginForm @success="redirect" title="J'ai déjà un compte" />
    </div>
    <div class="text-sm mt-2 flex flex-col sm:flex-row items-center justify-center">
      <p>Vous n'avez pas encore de compte ?</p>
      <router-link to="/signup" class="font-medium text-primary hover:text-primary ml-1"> Inscrivez-vous </router-link>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/LoginForm'

export default {
  components: {
    LoginForm
  },
  mounted() {},
  methods: {
    redirect() {
      this.$router.push(this.$route.query.redirect || '/shop')
    }
  }
}
</script>
