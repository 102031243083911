import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router'
import { setupApi } from '@/api'
import moment from 'moment'

import './styles/tailwind.css'
import '@themesberg/flowbite'

import FlagIcon from 'vue-flag-icon'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import './assets/tailwind.css'

/* add icons to the library */
library.add(fas, far, fab)

moment.locale('fr')

setupApi()

const app = createApp(App).component('font-awesome-icon', FontAwesomeIcon)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(pinia)

app.use(router)
app.use(FlagIcon)

app.mount('#app')
