import { http } from '@/api/index'

export default {
  validateGiftSubscription(clientId, deliveryInformation) {
    return http.post('/subscriptions/gift-activation', {
      clientId,
      deliveryInformation
    })
  },
  getSubscriptionsByUser(clientId) {
    return http.get(`/subscriptionsByUser?userId=${clientId}`)
  }
}
