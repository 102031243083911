<template>
  <footer class="text-white" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <img
      class="bg-primary rounded-full mx-auto h-16 md:h-32 w-auto m-4 -mb-11 md:-mb-20"
      src="@/assets/logo.png"
      alt="La Chaussette"
    />
    <div class="bg-primary">
      <div class="max-w-7xl mx-auto p-12 pt-20 px-4 sm:px-6 lg:px-8">
        <p class="text-sm text-center">L'abus d'alcool est dangereux pour la santé. À consommer avec modération.</p>
        <div class="md:grid md:grid-cols-5 md:grid-rows-1 md:gap-x-0 md:gap-y-8">
          <div class="col-start-1">
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in mainNavigation" :key="item.name">
                <router-link :to="item.to" class="text-base text-white hover:text-gray-300" v-bind="{ ...item.props }">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
          <div class="col-start-3 col-span-1">
            <div class="my-8">
              <div class="flex justify-center space-x-6 md:order-2">
                <a
                  v-for="item in navigation.social"
                  :key="item.name"
                  :href="item.href"
                  target="_blank"
                  class="text-white hover:text-gray-300"
                >
                  <span class="sr-only">{{ item.name }}</span>
                  <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                </a>
              </div>
            </div>
            <div class="flex flex-col text-center">
              <a href="mailto:contact@fitwine.fr" target="_blank">contact@fitwine.fr</a>
              <p>14 rue des Vignoles</p>
              <p>75020 Paris</p>
            </div>
          </div>
          <div class="col-start-5">
            <ul role="list" class="mt-4 space-y-4">
              <li v-for="item in navigation.legal" :key="item.name">
                <a :href="item.href" target="_blank" class="text-base text-white hover:text-gray-300">
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="mt-8 md:flex md:items-center md:justify-between">
          <p class="text-sm mt-8 text-gray-200 md:mt-0 md:order-1">
            &copy; 2022 FITWINE - LA CHAUSSETTE Tous droits réservés.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'
import { mapState } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'

const navigation = {
  social: [
    {
      name: 'Linkedin',
      href: 'https://www.linkedin.com/company/fit-wine',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/lachaussettevin',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    },
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/LaChaussetteVin',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd'
            })
          ])
      })
    }
  ],
  legal: [
    { name: 'Mentions légales', href: '/legal/legal_lachaussette.pdf' },
    { name: 'Conditions générales', href: '/legal/terms_lachaussette.pdf' },
    { name: 'Politique de confidentialité', href: '/legal/gdpr_lachaussette.pdf' },
    { name: 'Protection des mineurs', href: '/legal/minor_protection_lachaussette.pdf' }
  ]
}

export default {
  setup() {
    return {
      navigation
    }
  },
  computed: {
    ...mapState(useOrderStore, {
      order: state => state
    }),
    isGiftActive() {
      return this.order.paymentJourneyType === 'gift'
    },
    isSubscriptionActive() {
      return this.order.paymentJourneyType === 'subscription'
    },
    mainNavigation() {
      return [
        {
          name: "Je m'abonne",
          to: '/payment-journey?paymentJourneyType=subscription',
          props: { 'active-class': this.isSubscriptionActive ? 'router-link-active' : '' }
        },
        {
          name: "J'offre un abonnement",
          to: '/payment-journey?paymentJourneyType=gift',
          props: { 'active-class': this.isGiftActive ? 'router-link-active' : '' }
        },
        { name: 'Les compères', to: '/team', props: {} },
        { name: 'La boutique', to: '/shop', props: {} }
      ]
    }
  }
}
</script>
