<template>
  <!-- <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-xl font-extrabold text-gray-900">
        Connectez-vous à votre compte personnel !
      </h2>
    </div>
    <LoginForm/>
  </div> -->
  <div class="flex justify-center my-20">
    <Loader />
  </div>
</template>

<script>
// import LoginForm from '@/components/LoginForm';

import Loader from '@/components/Loader'
import { mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'

export default {
  components: {
    // LoginForm,
    Loader
  },
  mounted() {
    this.resetOrder()
    window.location = 'https://old.lachaussette.eu/common/PAGES/login.php'
  },
  methods: {
    ...mapActions(useOrderStore, ['resetOrder'])
  }
}
</script>
