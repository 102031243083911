<template>
  <div>
    <div class="home max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <!-- Head image -->
      <div class="max-w-4xl mx-auto">
        <img class="" alt="Chaussettes Header" src="@/assets/home/home_header_chaussettes.png" />
      </div>

      <!-- Punchline -->
      <p class="text-center text-base font-semibold text-black-600 tracking-wider mt-10">
        Surprenez vos sens et osez la dégustation à l’aveugle avec LA CHAUSSETTE®
      </p>

      <!-- Details list of 6 -->
      <div class="flex justify-center mt-10">
        <ul role="list" class="grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2 md:grid-cols-3 max-w-2xl">
          <li
            v-for="detail in details"
            :key="detail.img"
            class="col-span-1 flex flex-col text-center rounded-lg divide-y divide-gray-200"
          >
            <div class="flex-1 flex flex-col">
              <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full" :src="detail.img" :alt="detail.img" />
              <h3 class="mt-6 text-gray-900 text-sm font-semibold">{{ detail.title }}</h3>
              <h2 class="mt-6 text-gray-900 text-sm font-medium">{{ detail.description }}</h2>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- Card with CTAs -->
    <div class="bg-primary sm:rounded-lg max-w-3xl mt-10 mx-auto text-white">
      <div class="flex flex-col sm:flex-row">
        <div class="mx-auto mt-4 sm:m-4 w-1/2 sm:w-1/3 flex-shrink-0">
          <img class="sm:rounded-lg" alt="Chaussette Coffret" src="@/assets/home/home_coffret.jpeg" />
        </div>
        <div class="m-4">
          <h4 class="text-2xl font-semibold text-center sm:text-left">Prêts à vous lancer dans l'aventure avec</h4>
          <h4 class="text-2xl font-semibold text-center sm:text-left">LA CHAUSSETTE® ?</h4>
          <div class="w-1/4 h-1 border-t bg-white my-6 mx-auto sm:mx-0" />
          <p class="text-lg my-4 text-center sm:text-left">Choisissez votre abonnement dès maintenant !</p>
          <ul role="list" class="flex space-x-5 mt-4 justify-center sm:justify-start">
            <li>
              <router-link
                to="/payment-journey?paymentJourneyType=subscription"
                @click="scrollToTop"
                class="inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-white bg-secondary hover:bg-white hover:text-secondary focus:outline-none"
              >
                Je m'abonne
              </router-link>
            </li>
            <li>
              <router-link
                to="/payment-journey?paymentJourneyType=gift"
                @click="scrollToTop"
                class="inline-flex items-center px-4 py-2 text-base font-medium rounded-md text-white bg-secondary hover:bg-white hover:text-secondary focus:outline-none"
              >
                J'offre un abonnement
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'pinia'
import { useOrderStore } from '@/stores/subscriptionOrder'

const details = [
  {
    title: 'Deux découvertes',
    description: 'Recevez périodiquement deux bouteilles choisies avec amour',
    img: require('@/assets/home/home_detail_1.png')
  },
  {
    title: 'La chaussette',
    description: 'Reçue dans votre premier coffret, elle vous permet de cacher les étiquettes',
    img: require('@/assets/home/home_detail_2.png')
  },
  {
    title: 'Des conseils',
    description: 'Découvrez les conseils et les fiches de dégustation de chaque vin',
    img: require('@/assets/home/home_detail_3.png')
  },
  {
    title: 'Coup de cœur',
    description: 'Complétez votre cave avec vos découvertes grâce à votre espace abonné',
    img: require('@/assets/home/home_detail_4.png')
  },
  {
    title: 'Invitations',
    description: 'Rejoignez le club participez à nos séances de dégustation à l’aveugle',
    img: require('@/assets/home/home_detail_5.png')
  },
  {
    title: 'Abonnement',
    description: 'A partir de 29€/mois pour une livraison en Point Relais® en France (35€/mois à domicile)',
    img: require('@/assets/home/home_detail_7.png')
  }
]

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      details
    }
  },
  mounted() {
    this.resetOrder()
  },
  methods: {
    ...mapActions(useOrderStore, ['resetOrder']),
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
