import { http } from '@/api/index';

export default {
  getSubscriptionSession(sessionId) {
    return http.get(`/payments/subscription-session?sessionId=${sessionId}`)
  },
  createSubscriptionCheckoutSession(sessionData) {
    return http.post('/payments/subscription-session', sessionData)
  },
  getOrderSession(sessionId) {
    return http.get(`/payments/order-session?sessionId=${sessionId}`);
  },
  createOrderCheckoutSession(sessionData) {
    return http.post('/payments/order-session', sessionData);
  },
}
